
import CloudFun, { defineComponent, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import SliderEditor from '@/cloudfun/components/SliderEditor.vue'
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  components: {
    SliderEditor,
    Grid
  },
  setup() {
    const model = CloudFun.current?.model
    const grid = ref({})

    const gridOptions: GridOptions = {
      title: '相簿',
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      // exportConfig: {
      //   filename: '活動相簿清單',
      //   type: 'csv',
      //   types: ['html', 'csv'],
      //   mode: 'all',
      //   modes: ['current', 'selected', 'all'],
      //   columns: printColumns
      // },
      columns: [
        { field: 'Number', title: '編號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Title', title: '標題', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Published', title: '已發佈', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => cellValue ? '是' : '否' },
        { field: 'StartDate', title: '開始日期', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: 'EndDate', title: '結束日期', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: 'Ordinal', title: '排序', showHeaderOverflow: true, showOverflow: true, sortable: true }
      ],
      promises: {
        query: model ? (params) => model.dispatch('pictureAlbum/query', params) : undefined,
        queryAll: model ? () => model.dispatch('pictureAlbum/query') : undefined,
        save: model ? (params) => model.dispatch('pictureAlbum/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true }
    }
    const formOptions = {
      titleWidth: 80,
      titleAlign: 'right',
      items: [
        {
          field: 'Number',
          title: '編號',
          span: 16,
          itemRender: { name: '$input', props: { placeholder: '系統自動產生', disabled: true } }
        }, {
          field: 'Ordinal',
          title: '排序',
          span: 8,
          titleWidth: 60,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        }, {
          field: 'Title',
          title: '標題',
          span: 16,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } }
        }, {
          field: 'Published',
          title: '已發佈',
          span: 8,
          titleWidth: 60,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        }, {
          field: 'StartDate',
          title: '開始日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        }, {
          field: 'EndDate',
          title: '結束日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        }, {
          span: 24, slots: { default: 'form-pictures' }
        },
      ],
      rules: {
        Title: [{ type: 'string', max: 128, required: true }]
      }
    }

    const sliderWidth = ref('100%')
    const pictureFormOptions = {
      titleWidth: 40,
      titleAlign: 'right',
      items: [
        {
          field: 'Name',
          title: '名稱',
          span: 14,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } }
        }, {
          field: 'Ordinal',
          title: '順序',
          span: 10,
          titleWidth: 40,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        }, {
          field: 'Remark',
          title: '備註',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } }
        },
      ],
      rules: {
        Name: [{ type: 'string', max: 128 }]
      }
    }

    return {
      grid,
      gridOptions,
      formOptions,
      sliderWidth,
      pictureFormOptions,
      baseUrl: process.env.VUE_APP_BACKEND_URL
    }
  },
  methods: {
    onGridModalResize(size: any) {
      this.sliderWidth = `${size.width - 100}px`
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id ? await this.$model.dispatch('pictureAlbum/find', row.Id) : undefined
      if (entity) Object.assign(row, entity)
      if (row.Pictures) row.Pictures = row.Pictures.sort((a: any, b: any) => a.Ordinal - b.Ordinal)
      callback()
    },
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash('#batch-dropdown').dropdown('hide')
      callback()
    },
    onSliderEdit(image: any, callback: any) {
      image.AlbumId = (this.grid as any).editingRow.Id
      callback()
    },
    async onSliderRemove(image: any, callback: any) {
      await this.$model.dispatch('picture/delete', image.Id)
      callback()
    },
    async onSliderSave(params: any, callback: any) {
      await this.$model.dispatch('picture/save', params).then(
        () => {
          this.$model.dispatch('pictureAlbum/find', (this.grid as any).editingRow.Id).then(
            (entity) => {
              (this.$refs.sliderEditor as any).reload(entity.Pictures.sort((a: any, b: any) => a.Ordinal - b.Ordinal))
            },
            reason => { CloudFun.send('error', { subject: '重載失敗', content: reason }) }
          )
        },
        reason => { CloudFun.send('error', { subject: '保存失敗', content: reason }) }
      )
      callback()
    }
  }
})
